import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  ICreateQuestion,
  IQuestion,
  IVertical,
  TQuestion,
} from '../types/Question';
import { ILink, IUpdateLink } from '../types/Link';
import { IOrganization } from '../types/Organization';
import { IEstimate } from '../types/Estimate';
import { ICreateAnswer } from '../types/Answer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IGroupedEstimate } from '../types/IGroupedEstimate';
import { IManager } from '../types/Manager';

export interface IEstimatesFilter {
  verticalId?: number;
  year?: number;
  quarter?: number;
  managerId?: number;
  organizationId?: number;
}

export interface IOrganizationFilters {
  organizationId?: number;
  managerId?: number;
}

export function useLink(linkId?: string) {
  const apiUrl = process.env.REACT_APP_API_URL;
  //process..env.REACT_APP_API_URL;
  const [link, setLink] = useState<ILink | undefined>(undefined);
  const [links, setLinks] = useState<ILink[] | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);

  const [organizations, setOrganizations] = useState<
    IOrganization[] | undefined
  >(undefined);
  const [verticals, setVerticals] = useState<IVertical[] | undefined>(
    undefined,
  );
  const [questions, setQuestions] = useState<IQuestion[] | undefined>(
    undefined,
  );
  const [groupedEstimates, setGroupedEstimates] = useState<
    IGroupedEstimate[] | undefined
  >(undefined);

  const [organizationsSelected, setOrganizationsSelected] = useState<
    IOrganization[] | undefined
  >(undefined);

  const [managers, setManagers] = useState<IManager[] | undefined>(undefined);

  const jwt = useSelector((state: RootState) => state.me.jwt);
  const getLink = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/link/${linkId}`,
      );
      if (!data.status) {
        setLink(data);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const getLinks = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(`${apiUrl}/link/`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      if (data) {
        setLinks(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
      localStorage.clear();
    }
  };

  const updateLink = async (id: string, data: IUpdateLink) => {
    try {
      await axios.patch(`${apiUrl}/link/${id}`, data, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      await getOrganizations();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const createLink = async (data: IUpdateLink) => {
    try {
      await axios.post(`${apiUrl}/link/`, data, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      await getOrganizations();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const deleteLink = async (linkId: string): Promise<boolean> => {
    try {
      await axios.delete(`${apiUrl}/link/${linkId}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      await getOrganizations();
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const createEstimate = async (linkId: string, estimate: IEstimate) => {
    try {
      await axios.post(`${apiUrl}/estimate`, estimate, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createEstimates = async (estimate: IEstimate[]) => {
    try {
      await axios.post(`${apiUrl}/estimate/many`, estimate);
    } catch (error) {
      console.log(error);
    }
  };

  const createAnswer = async (answer: ICreateAnswer) => {
    try {
      await axios.post(`${apiUrl}/answers`, answer, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizations = async (filters?: IOrganizationFilters) => {
    try {
      let params = '';
      if (filters && Object.keys(filters).length > 0) {
        params = `?${new URLSearchParams(filters as any).toString()}`;
      }

      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/client${params}`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );
      if (data) {
        setOrganizations(data.items);
      } else {
        setError(data);
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getOrganizationsSelect = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/client/select`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );
      if (data) {
        setOrganizationsSelected(data.items);
      } else {
        setError(data);
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getVerticals = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(`${apiUrl}/verticals/`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });

      if (data) {
        setVerticals(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateQuestion = async (id: string, data: TQuestion) => {
    try {
      await axios.patch(`${apiUrl}/questions/${id}`, data, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      await getQuestions();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const getQuestions = async (verticalId?: number) => {
    try {
      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/questions/${verticalId ? verticalId : ''}`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );

      if (data) {
        setQuestions(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const getGroupedEstimates = async (filters?: IEstimatesFilter) => {
    try {
      let params = '';
      if (filters) {
        params = `?${new URLSearchParams(filters as any).toString()}`;
      }

      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/estimate/grouped/links${params}`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );
      if (data) {
        setGroupedEstimates(data);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const createQuestion = async (data: ICreateQuestion) => {
    try {
      await axios.post(`${apiUrl}/questions/`, data, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      await getQuestions();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const deleteQuestion = async (questionId: string): Promise<boolean> => {
    try {
      await axios.delete(`${apiUrl}/questions/${questionId}`, {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      });
      await getQuestions();
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const getManagers = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/client/managers`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );
      if (data) {
        setManagers(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const insertQuestionToVertical = async (
    verticalId: number,
    questionId: string,
  ): Promise<any> => {
    try {
      await axios.post(
        `${apiUrl}/question-to-verticals/`,
        {
          verticalId,
          questionId,
        },
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );
      await getQuestions();
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const removeQuestionToVertical = async (
    verticalId: number,
    questionId: string,
  ): Promise<any> => {
    try {
      await axios.delete(
        `${apiUrl}/question-to-verticals/${questionId}/${verticalId}`,
        {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      );
      await getQuestions();
    } catch (error) {
      setError(error);
      return false;
    }
  };

  useEffect(() => {
    if (linkId && !error && !link) {
      getLink();
    }
  }, [linkId, error, link]);

  return {
    link,
    links,
    createEstimate,
    createEstimates,
    getLink,
    getLinks,
    organizations,
    organizationsSelected,
    getOrganizationsSelect,
    getOrganizations,
    updateLink,
    createLink,
    deleteLink,
    error,
    setError,
    createQuestion,
    deleteQuestion,
    createAnswer,
    getVerticals,
    insertQuestionToVertical,
    removeQuestionToVertical,
    verticals,
    questions,
    getQuestions,
    updateQuestion,
    getGroupedEstimates,
    groupedEstimates,
    managers,
    getManagers,
  };
}
