import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  unwrapResult,
} from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { IMe } from '../../common/types/IMe';

interface UserState {
  data: IMe | undefined;
  loading: boolean;
  error: string | null;
  jwt: string | null;
  refreshToken: string | null;
}

const initialState: UserState = {
  data: undefined,
  loading: false,
  error: null,
  jwt: null,
  refreshToken: null,
};

// Define the async thunk for fetching user data
export const fetchMe = createAsyncThunk<IMe, string, { rejectValue: string }>(
  'user/fetchMe',
  async (jwtToken, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}/auth/me`, {
        headers: {
          authorization: `Bearer ${jwtToken}`,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log('test');
      const refreshResult = await dispatch(fetchRefreshToken());
      if (fetchRefreshToken.fulfilled.match(refreshResult)) {
        try {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/auth/me`,
            {
              headers: {
                authorization: `Bearer ${refreshResult.payload.accessToken}`,
              },
            },
          );
          return response.data;
        } catch (error: any) {
          dispatch(logout());
          return rejectWithValue(error.response.data);
        }
      }
      dispatch(logout());
      return rejectWithValue(error.response.data);
    }
  },
);

// Define the async thunk for authenticating
export const authenticate = createAsyncThunk<
  { user: IMe; jwt: string; refreshToken: string },
  { publicKey: string; privateKey: string },
  { rejectValue: string }
>(
  'user/authenticate',
  async ({ publicKey, privateKey }, { dispatch, rejectWithValue }) => {
    try {
      const { data }: AxiosResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          publicKey: publicKey,
          privateKey: privateKey,
        },
      );
      if (data.accessToken) {
        localStorage.setItem('jwt', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        const user = unwrapResult(await dispatch(fetchMe(data.accessToken)));
        return {
          user,
          jwt: data.accessToken,
          refreshToken: data.refreshToken,
        };
      } else {
        return rejectWithValue('Authentication failed');
      }
    } catch (error: any) {
      localStorage.clear();
      return rejectWithValue(error.response.data);
    }
  },
);

// Define the async thunk for fetching a refresh token
export const fetchRefreshToken = createAsyncThunk<
  { accessToken: string; refreshToken: string },
  void,
  { rejectValue: string }
>('user/fetchRefreshToken', async (_, { rejectWithValue }) => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return rejectWithValue('No refresh token available');
    }

    const response: AxiosResponse<{
      accessToken: string;
      refreshToken: string;
    }> = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
      {
        refreshToken,
      },
    );

    const { accessToken, refreshToken: newRefreshToken } = response.data;

    localStorage.setItem('jwt', accessToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    return { accessToken, refreshToken: newRefreshToken };
  } catch (error: any) {
    localStorage.clear();
    return rejectWithValue(error.response.data);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IMe | undefined>) => {
      state.data = action.payload;
    },
    logout: (state) => {
      state.data = undefined;
      state.jwt = null;
      state.refreshToken = null;
      state.loading = false;
      state.error = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMe.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMe.fulfilled, (state, action: PayloadAction<IMe>) => {
        state.loading = false;
        state.data = action.payload;
        state.jwt = localStorage.getItem('jwt');
        state.refreshToken = localStorage.getItem('refreshToken');
      })
      .addCase(fetchMe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(authenticate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.user;
        state.jwt = action.payload.jwt;
        state.refreshToken = action.payload.refreshToken;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchRefreshToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchRefreshToken.fulfilled,
        (
          state,
          action: PayloadAction<{ accessToken: string; refreshToken: string }>,
        ) => {
          state.loading = false;
          state.jwt = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        },
      )
      .addCase(fetchRefreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setUser, logout } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;
