import styles from './primary-button.module.css';
import { EColors } from '../../enums/colors';

export interface IButtonProps {
  onClick: () => void;
  text: string;
  isPlusVisible?: boolean;
}
export const PrimaryButton = ({
  onClick,
  text,
  isPlusVisible = true,
}: IButtonProps) => {
  return (
    <div className={styles.buttonContainer} onClick={onClick}>
      {isPlusVisible && (
        <p
          style={{
            fontSize: '40px',
            color: EColors.CHARCOAL_GRAY,
            lineHeight: '33px',
            margin: 0,
            paddingRight: '6px',
            paddingBottom: '6px',
          }}
        >
          +{' '}
        </p>
      )}
      <p
        style={{
          fontSize: '22px',
          color: EColors.CHARCOAL_GRAY,
          lineHeight: '27px',
          margin: '6px 0',
        }}
      >
        {text}
      </p>
    </div>
  );
};
