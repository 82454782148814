import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import styles from './estimate-page.module.css';
import { Select } from 'antd';
import { EstimatesTable } from '../../components/EstimatesTable/EstimatesTable';
import { IEstimatesFilter, useLink } from '../../hooks/useLink';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import {
  EstimateToExcelHeader,
  exportToExcel,
  formatEstimatesToExport,
  generateEstimatesFilename,
} from '../../../utils/utils';

export const EstimatesPage = () => {
  const me = useSelector((state: RootState) => state.me.data);

  const [filters, setFilters] = useState<IEstimatesFilter>({});
  const [years, setYears] = useState<Array<number>>();
  const [quarters, setQuarters] = useState<Array<number>>();
  const [verticalName, setVerticalName] = useState<string | undefined>();
  const {
    groupedEstimates,
    getGroupedEstimates,
    verticals,
    getVerticals,
    managers,
    getManagers,
    organizationsSelected,
    getOrganizationsSelect,
  } = useLink();

  useEffect(() => {
    const fetchData = async () => {
      await getGroupedEstimates();
      await getVerticals();
      await getManagers();
      await getOrganizationsSelect();
    };
    if (me && !groupedEstimates) {
      fetchData();
    }
  }, [me]);

  useEffect(() => {
    const fetchData = async () => {
      await getGroupedEstimates(filters);
    };

    fetchData();
  }, [filters]);

  useEffect(() => {
    if (groupedEstimates && !years && !quarters) {
      const bufferEstimates = groupedEstimates.filter(
        (estimate) => estimate.year && estimate.quarter,
      );

      setYears(
        Array.from(new Set(bufferEstimates?.map((estimate) => estimate.year!))),
      );
      setQuarters(
        Array.from(
          new Set(bufferEstimates?.map((estimate) => estimate.quarter!)),
        ),
      );
    }
  }, [groupedEstimates]);

  const handleExport = () => {
    if (!groupedEstimates) {
      return;
    }

    const formattedData = formatEstimatesToExport(groupedEstimates);
    const filename = generateEstimatesFilename(filters, verticalName);
    exportToExcel(formattedData, filename, EstimateToExcelHeader);
  };

  const handleDropFilters = () => {
    setFilters({});
  };
  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Пройденные опросы</h1>
      <div className={styles.buttonContainer}>
        <PrimaryButton onClick={handleExport} text={'Выгрузить'} />
        <div className={styles.filterContainer}>
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Вертикаль'}
            value={filters.verticalId}
            style={{ width: '150px' }}
            onChange={(verticalId) => {
              verticalId
                ? setFilters({ ...filters, verticalId })
                : setFilters((prevState) => ({
                    ...(prevState.managerId && {
                      managerId: prevState.managerId,
                    }),
                    ...(prevState.quarter && { quarter: prevState.quarter }),
                    ...(prevState.year && { year: prevState.year }),
                    ...(prevState.organizationId && {
                      organizationId: prevState.organizationId,
                    }),
                  }));

              verticals &&
                setVerticalName(
                  verticals.find((vertical) => vertical.id === verticalId)
                    ?.title,
                );
            }}
            options={verticals?.map((vertical) => {
              return {
                value: vertical.id,
                label: vertical.title,
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Год'}
            value={filters.year}
            style={{ width: '150px' }}
            onChange={(year) => {
              year
                ? setFilters({ ...filters, year })
                : setFilters((prevState) => ({
                    verticalId: prevState.verticalId,
                    quarter: prevState.quarter,
                  }));
            }}
            options={years?.map((year) => {
              return {
                value: year.toString(),
                label: year.toString(),
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Квартал'}
            value={filters.quarter}
            style={{ width: '150px' }}
            onChange={(quarter) => {
              quarter
                ? setFilters({ ...filters, quarter })
                : setFilters((prevState) => ({
                    ...(prevState.managerId && {
                      managerId: prevState.managerId,
                    }),
                    ...(prevState.verticalId && {
                      verticalId: prevState.verticalId,
                    }),
                    ...(prevState.year && { year: prevState.year }),
                    ...(prevState.organizationId && {
                      organizationId: prevState.organizationId,
                    }),
                  }));
            }}
            options={quarters?.map((quarters) => {
              return {
                value: quarters.toString(),
                label: quarters.toString(),
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Организация'}
            value={filters.organizationId}
            style={{ width: '150px' }}
            onChange={(organizationId) => {
              organizationId
                ? setFilters({ ...filters, organizationId })
                : setFilters((prevState) => ({
                    ...(prevState.managerId && {
                      managerId: prevState.managerId,
                    }),
                    ...(prevState.verticalId && {
                      verticalId: prevState.verticalId,
                    }),
                    ...(prevState.year && { year: prevState.year }),
                    ...(prevState.quarter && { quarter: prevState.quarter }),
                  }));
            }}
            options={organizationsSelected?.map((organization) => {
              return {
                value: organization.id,
                label: organization.title,
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Менеджер'}
            value={filters.managerId}
            style={{ width: '150px' }}
            onChange={(managerId) => {
              managerId
                ? setFilters({ ...filters, managerId })
                : setFilters((prevState) => ({
                    ...(prevState.organizationId && {
                      organizationId: prevState.organizationId,
                    }),
                    ...(prevState.verticalId && {
                      verticalId: prevState.verticalId,
                    }),
                    ...(prevState.year && { year: prevState.year }),
                    ...(prevState.quarter && { quarter: prevState.quarter }),
                  }));
            }}
            options={managers?.map((manager) => {
              return {
                value: manager.id,
                label: `${manager.surname} ${manager.name}`,
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
        </div>
        <PrimaryButton
          onClick={handleDropFilters}
          text={'Сбросить фильтры'}
          isPlusVisible={false}
        />
      </div>
      <EstimatesTable estimates={groupedEstimates} />
    </div>
  );
};
