import React, { useEffect, useState } from 'react';
import { useLink } from '../../hooks/useLink';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import styles from './questions-page.module.css';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import { Modal } from '../../components/Modal/Modal';
import { Button, Form, Select } from 'antd';
import { EColors } from '../../enums/colors';
import { TQuestion } from '../../types/Question';
import TextArea from 'antd/es/input/TextArea';
import { ERoles } from '../../enums/roles';
import { QuestionsTable } from '../../components/QuestionsTable/QuestionsTable';

export const QuestionsPage = () => {
  const me = useSelector((state: RootState) => state.me.data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const {
    createQuestion,
    deleteQuestion,
    getVerticals,
    insertQuestionToVertical,
    removeQuestionToVertical,
    verticals,
    questions,
    getQuestions,
    updateQuestion,
  } = useLink();

  useEffect(() => {
    const fetchData = async () => {
      await getVerticals();
      await getQuestions();
    };
    if (me) {
      fetchData();
    }
  }, [me]);

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Вопросы</h1>
      <div className={styles.buttonContainer}>
        <PrimaryButton onClick={handleOpenModal} text={'Cоздать вопрос'} />
        <Select
          showSearch
          allowClear={true}
          className={'select'}
          placeholder={'Вертикаль'}
          style={{ width: '250px', marginLeft: '100px' }}
          onChange={async (v) => {
            await getQuestions(v);
          }}
          options={verticals?.map((vertical) => {
            return {
              value: vertical.id,
              label: vertical.title,
            };
          })}
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
          }
        />
      </div>
      <QuestionsTable
        deleteQuestion={deleteQuestion}
        questions={questions}
        verticals={verticals}
        insertQuestionToVertical={insertQuestionToVertical}
        removeQuestionToVertical={removeQuestionToVertical}
        updateQuestion={updateQuestion}
        getQuestions={getQuestions}
        isEditable={me?.role.role !== ERoles.MANAGER}
      />
      <Modal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        className={styles.modal}
      >
        <p
          style={{
            fontSize: '28px',
            color: EColors.GRANITE_GRAY,
            textWrap: 'wrap',
            fontWeight: 700,
            lineHeight: '36px',
            fontFamily: 'Manrope',
          }}
        >
          Создание Вопроса
        </p>
        <Form
          name="question"
          onFinish={async (values: TQuestion) => {
            await createQuestion({
              text: values.text,
              responseType: values.responseType,
            });
          }}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '10px',
            justifyContent: 'start',
          }}
        >
          <Form.Item<TQuestion>
            name="text"
            rules={[
              {
                required: true,
                message: 'Поле необходимо заполнить!',
              },
            ]}
          >
            <TextArea
              placeholder={'Вопрос'}
              className={'select'}
              style={{ width: '283px' }}
            />
          </Form.Item>
          <Form.Item<TQuestion>
            name="responseType"
            rules={[
              {
                required: true,
                message: 'Поле необходимо заполнить!',
              },
            ]}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Тип'}
              style={{ width: '283px' }}
              options={[
                {
                  value: 'ESTIMATE',
                  label: 'Оценка',
                },
                {
                  value: 'COMMENT',
                  label: 'Комментарий',
                },
              ]}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase(),
                )
              }
            />
          </Form.Item>
          <Form.Item style={{ width: '100%' }}>
            <Button
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'black',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                fontFamily: 'Manrope',
              }}
              className={styles.button}
              type="primary"
              htmlType="submit"
            >
              Ок
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
