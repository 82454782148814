import React, { createElement } from 'react';
import {
  Button,
  Form,
  message,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { IGroupedEstimate } from '../../types/IGroupedEstimate';
import { CopyOutlined } from '@ant-design/icons';
import { IEstimate } from '../../types/Estimate';
import { EQuestion } from '../../types/Question';
import moment from 'moment';

export interface Item extends IGroupedEstimate {
  key: string;
}

export interface IExpandedItem extends IEstimate {
  key: string;
}
interface IEstimateTableProps {
  estimates?: IGroupedEstimate[];
}

export const EstimatesTable = ({ estimates }: IEstimateTableProps) => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Клиент',
      dataIndex: 'clientTitle',
      width: '20%',
      editable: false,
      render: (_: any, record: Item) => {
        return <Typography.Text>{`${record.clientTitle}`}</Typography.Text>;
      },
    },
    {
      title: 'Менеджер',
      dataIndex: 'managerName',
      width: '20%',
      editable: false,
      render: (_: any, record: Item) => {
        return (
          <Typography.Text>
            {`${record.managerSurname} ${record.managerName}  ${
              record.managerMiddlename || ''
            }`}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Вертикаль',
      dataIndex: 'verticalTitle',
      width: '20%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.verticalTitle}</Typography.Text>;
      },
    },
    {
      title: 'Ссылка',
      dataIndex: 'linkId',
      width: '30%',
      editable: false,
      render: (_: any, record: Item) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography.Text>{`https://nps.dev.artics.ru/${record.linkId}`}</Typography.Text>
            <Button
              icon={createElement(CopyOutlined)}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${window.location.origin}/${record.linkId}`,
                );
                message.success('Ссылка скопирована в буфер обмена');
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Средняя оценка',
      dataIndex: 'averageEstimate',
      width: '20%',
      editable: true,
      render: (_: any, record: Item) => {
        return (
          <Typography.Text>
            {parseFloat(record.averageEstimate).toFixed(2)}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Кол-во оценок',
      dataIndex: 'estimateCount',
      width: '20%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.estimateCount}</Typography.Text>;
      },
    },
  ];

  const expandColumns: TableColumnsType<IExpandedItem> = [
    {
      title: 'Дата',
      dataIndex: 'createAt',
      key: 'date',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Вопрос',
      dataIndex: 'question',
      key: 'question',
      render: (_: any, record: IExpandedItem) => record.question?.text,
    },
    { title: 'Комментарий', dataIndex: 'comment', key: 'comment' },
    { title: 'Оценка', dataIndex: 'estimate', key: 'name' },
  ];

  const expandedRowRender = (record: Item) => (
    <Table<IExpandedItem>
      columns={expandColumns}
      dataSource={record.details?.map((detail) => ({
        ...detail,
        key: detail.linkId,
      }))}
      pagination={false}
    />
  );

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        dataSource={estimates?.map((estimate) => ({
          ...estimate,
          key: estimate.linkId,
        }))}
        expandable={{
          expandedRowRender, // Attach expandable row render function
          rowExpandable: (record) =>
            !!record.details && record.details.length > 0, // Only expand if there are details
        }}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5,
          style: {
            backgroundColor: 'white',
            margin: '0',
          },
        }}
      />
    </Form>
  );
};
