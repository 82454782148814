import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useLink } from '../../hooks/useLink';
import { IEstimate } from '../../types/Estimate';
import { EQuestion } from '../../types/Question';
import ReactModal from 'react-modal';
import { footerStyle } from '../../styles/styles';
import { Footer } from 'antd/es/layout/layout';
import styles from '../AdminLayout/layout.module.css';
import { Range } from 'react-range';
import cn from 'classnames';
import { PollFooter } from '../PollFooter/PollFooter';

ReactModal.setAppElement('#modal');

export const Poll = () => {
  const { linkId } = useParams();
  const { link, createEstimates, error } = useLink(linkId);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [responses, setResponses] = useState<IEstimate[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [clickedButtons, setClickedButtons] = useState<{
    [key: string]: number | null;
  }>({});

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    if (
      linkId &&
      link?.vertical?.questionToVerticals &&
      link?.vertical.questionToVerticals.length > 0
    ) {
      const responses: IEstimate[] = link?.vertical.questionToVerticals.map(
        (questionToVerticals) => {
          return {
            estimate: undefined,
            linkId,
            questionId: questionToVerticals.question.id,
            comment: undefined,
          };
        },
      );
      setResponses(responses);
    }
  }, [linkId, link?.vertical?.questionToVerticals]);

  const handleScoreClick = (score: number, questionId: string) => {
    setClickedButtons((prevClickedButtons) => ({
      ...prevClickedButtons,
      [questionId]: score,
    }));
    const updatedResponses = responses.map((response) =>
      response.questionId === questionId
        ? { ...response, estimate: score }
        : response,
    );
    setResponses(updatedResponses);
  };
  //
  // const getStyle = () =>
  //   responses.every((response) => response.comment || response.estimate);
  //
  // const areAllResponsesComplete = () => {
  //   return responses.every((response) => {
  //     return response.estimate === undefined || response.comment === undefined;
  //   });
  // };

  const generateScoreButtons = (questionId: string) => {
    const buttons = [];
    const clickedButton = clickedButtons[questionId];
    for (let score = 0; score <= 10; score++) {
      buttons.push(
        <button
          className={
            score === clickedButton
              ? styles.roundButtonPushed
              : styles.roundButton
          }
          key={score}
          onClick={() => handleScoreClick(score, questionId)}
        >
          {score}
        </button>,
      );
    }
    return buttons;
  };
  return (
    <div
      style={{
        background: '#FFFFFF',
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        overflow: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {/*<div*/}
      {/*  style={{*/}
      {/*    height: '100%',*/}
      {/*    width: '100%',*/}
      {/*    position: 'relative',*/}
      {/*    display: 'flex',*/}
      {/*    flexDirection: 'row',*/}
      {/*  }}*/}
      {/*>*/}
      <div className={styles.logo}>
        <img src={'/red-logo.svg'} className={styles.logoImage} alt={'logo'} />
      </div>
      {link && (
        <div className={styles.backgroundContainer}>
          <img
            src={'/fon.png'}
            className={styles.backgroundImage}
            alt={'background'}
          />
        </div>
      )}
      {link ? (
        <div className={styles.mainContainer}>
          <>
            <h1 className={styles.header}>
              {link.manager?.name
                ? `${link.manager.name}, здравствуйте!`
                : `Здравствуйте!`}
              <br />
            </h1>

            <h2 className={styles.secondHeader}>
              Пожалуйста, уделите несколько минут обратной связи о нашей работе.{' '}
              Ваше мнение действительно ценно и поможет нам стать лучше!
            </h2>

            {link?.vertical?.questionToVerticals &&
            link?.vertical?.questionToVerticals.length > 0 ? (
              <>
                {link?.vertical?.questionToVerticals?.map(
                  (questionToVerticals, index) => (
                    <div key={index} className={styles.questionContainer}>
                      {questionToVerticals.question.responseType ===
                        EQuestion.ESTIMATE && (
                        <>
                          <p className={styles.questionHeader}>
                            {questionToVerticals.question.text}
                          </p>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                            }}
                          >
                            {!isMobile ? (
                              <>
                                <div className={styles.buttonContainer}>
                                  {generateScoreButtons(
                                    questionToVerticals.questionId,
                                  )}
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  height: '100px',
                                  alignItems: 'end',
                                  paddingBottom: '30px',
                                }}
                                className={styles.rangeContainer}
                              >
                                <Range
                                  label="Select your value"
                                  step={1}
                                  min={0}
                                  max={10}
                                  values={[
                                    ...(clickedButtons[
                                      questionToVerticals.questionId
                                    ]
                                      ? [
                                          clickedButtons[
                                            questionToVerticals.questionId
                                          ] || 0,
                                        ]
                                      : [0]),
                                  ]}
                                  onChange={(values) =>
                                    handleScoreClick(
                                      values[0],
                                      questionToVerticals.questionId,
                                    )
                                  }
                                  renderMark={({ props }) => (
                                    <div
                                      {...props}
                                      style={{
                                        ...props.style,
                                        position: 'absolute',
                                        top: '-1px',
                                        height: '4px',
                                        width: '1px',
                                        backgroundColor: '#C6C7CB',
                                      }}
                                    ></div>
                                  )}
                                  renderTrack={({ props, children }) => (
                                    <div
                                      {...props}
                                      style={{
                                        ...props.style,
                                        height: '1px',
                                        width: '100%',
                                        backgroundColor: '#C6C7CB',
                                      }}
                                    >
                                      {children}
                                    </div>
                                  )}
                                  renderThumb={({ props }) => (
                                    <div
                                      {...props}
                                      key={props.key}
                                      style={{
                                        height: '36px',
                                        width: '36px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        gap: '7px',
                                        justifyContent: 'center',
                                        backgroundColor: '#3F444F',
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: 'absolute',
                                          width: '1px',
                                          top: '-24px',
                                          height: '24px',
                                          backgroundColor: '#9C162E',
                                        }}
                                      />
                                      <div
                                        style={{
                                          position: 'absolute',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          top: '-72px',
                                          width: '48px',
                                          height: '48px',
                                          borderRadius: '50%',
                                          fontWeight: '500',
                                          color: '#fff',
                                          fontSize: '16px',
                                          backgroundColor: '#9C162E',
                                        }}
                                      >
                                        {
                                          [
                                            ...(clickedButtons[
                                              questionToVerticals.questionId
                                            ]
                                              ? [
                                                  clickedButtons[
                                                    questionToVerticals
                                                      .questionId
                                                  ] || 0,
                                                ]
                                              : [0]),
                                          ][0]
                                        }
                                      </div>
                                      <div
                                        style={{
                                          width: '6px',
                                          rotate: '180deg',
                                          background: 'url(/arrow.svg)',
                                          backgroundPosition: 'center',
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'contain',
                                        }}
                                      />
                                      <div
                                        style={{
                                          width: '6px',
                                          background: 'url(/arrow.svg)',
                                          backgroundPosition: 'center',
                                          backgroundRepeat: 'no-repeat',
                                          backgroundSize: 'contain',
                                        }}
                                      />
                                    </div>
                                  )}
                                />
                              </div>
                            )}
                            <div className={styles.buttonSignContainer}>
                              <p className={styles.buttonSign}>
                                0 - крайне неудовлетворен
                              </p>
                              <p className={styles.buttonSign}>
                                10 - выше ожиданий
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      <h2 className={styles.commentHeader}>
                        {questionToVerticals.question.responseType ===
                        EQuestion.ESTIMATE
                          ? 'Почему вы поставили именно такую оценку?'
                          : questionToVerticals.question.text}
                      </h2>
                      <textarea
                        className={styles.textArea}
                        value={
                          responses.find((response) => {
                            return (
                              response.questionId ===
                              questionToVerticals.questionId
                            );
                          })?.comment
                        }
                        onChange={(e) => {
                          const updatedResponses = responses.map((response) => {
                            if (
                              response.questionId ===
                              questionToVerticals.questionId
                            ) {
                              return {
                                ...response,
                                comment:
                                  e.target.value === ''
                                    ? undefined
                                    : e.target.value,
                              };
                            }
                            return response;
                          });
                          setResponses(updatedResponses);
                        }}
                        placeholder={'Введите текст'}
                      />
                    </div>
                  ),
                )}
                <div className={styles.submitButtonContainer}>
                  <button
                    className={cn(
                      styles.submitButton,
                      styles.submitButtonActive,
                    )}
                    // disabled={!getStyle()}
                    style={{ color: 'white' }}
                    onClick={async () => {
                      await createEstimates(responses);
                      navigate('/success');
                    }}
                  >
                    <p className={styles.submitButtonText}>ОТПРАВИТЬ ОТВЕТЫ</p>
                  </button>
                </div>
              </>
            ) : (
              // <DefaultQuestion
              //   generateScoreButtons={generateScoreButtons}
              //   estimate={estimate}
              //   setComment={setComment}
              //   comment={comment}
              //   linkId={link?.id}
              //   createEstimate={createEstimate}
              //   goNext={goNext}
              // />
              <div></div>
            )}
          </>
        </div>
      ) : (
        <div
          style={{
            overflow: 'hidden',
            height: '100vh',
          }}
        >
          <div className={styles.backgroundContainer}>
            <img
              src={'/fon.png'}
              className={styles.backgroundImageSuccess}
              alt={'background'}
            />
          </div>

          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!!error ? (
              <h1 style={{ color: '#3F444F' }}>
                В этом месяце вы уже проголосовали
              </h1>
            ) : (
              <h1 style={{ color: '#3F444F' }}>Загрузка...</h1>
            )}
          </div>
        </div>
      )}
      {/*</div>*/}
      <Footer style={footerStyle}>
        <div className={styles.footerContainer}>
          <img src={'/logo.svg'} alt={'logo'} />
          <p className={styles.footerText}>© 2000 - 2024 Артикс</p>
        </div>
        <div className={styles.socialsContainer}>
          <div
            onClick={() => {
              window.open('https://t.me/truedigitalclub', '_blank');
            }}
            className={styles.social}
          >
            <img
              className={styles.socialImage}
              src={'/telegram.svg'}
              alt={'telegram'}
            />
          </div>
          <div
            onClick={() => {
              window.open('https://vk.com/articsru', '_blank');
            }}
            className={styles.social}
          >
            <img className={styles.socialImage} src={'/vk.svg'} alt={'vk'} />
          </div>
          <div
            onClick={() => {
              window.open('https://truedigital.ru/', '_blank');
            }}
            className={styles.social}
          >
            <img
              className={styles.socialImage}
              src={'/trueDigital.svg'}
              alt={'vk'}
            />
          </div>
        </div>
      </Footer>
      {/*<Modal*/}
      {/*  isModalOpen={isModalOpen}*/}
      {/*  handleCloseModal={handleCloseModal}*/}
      {/*  className={styles.modal}*/}
      {/*>*/}
      {/*  /!*<button onClick={handleCloseModal} className={'closeButton'}>*!/*/}
      {/*  /!*  <img src={'/cross.png'} className={styles.crossImage} />*!/*/}
      {/*  /!*</button>*!/*/}
      {/*  <p className={styles.modalHeader}>*/}
      {/*    {link?.manager?.name}, спасибо <br /> за вашу обратную связь!*/}
      {/*  </p>*/}
      {/*  <button onClick={handleCloseModal} className={styles.okButton}>*/}
      {/*    ОК*/}
      {/*  </button>*/}
      {/*</Modal>*/}
    </div>
  );
};
