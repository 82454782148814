import React from 'react';
import { EColors } from '../enums/colors';

export const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  overflow: 'hidden',
  minHeight: 120,
  color: '#fff',
  backgroundColor: EColors.PALE_GRAY,
};

export const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  overflow: 'hidden',
  width: '500px',
  color: '#fff',
  backgroundColor: EColors.LIGHT_GRAY,
};

export const footerStyle: React.CSSProperties = {
  display: 'flex',
  zIndex: '1000',
  position: 'relative',
  justifyContent: 'space-between',
  padding: '40px 30px',
  textAlign: 'center',
  height: '155px',
  color: '#fff',
  backgroundColor: EColors.SLATE_GRAY,
};

export const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%',
};
