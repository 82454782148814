import React, { useEffect, useState } from 'react';
import { IOrganizationFilters, useLink } from '../../hooks/useLink';
import { IManager } from '../../types/Manager';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Item, LinksTable } from '../../components/LinksTable/LinksTable';
import styles from './polls-page.module.css';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import { Modal } from '../../components/Modal/Modal';
import { Button, Form, Select } from 'antd';
import { EColors } from '../../enums/colors';

type FieldType = {
  forOrganizationId?: string;
  isMultiplyResponse?: boolean;
  managerId?: string;
  verticalId?: number;
};

export const PollsPage = () => {
  const me = useSelector((state: RootState) => state.me.data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const {
    links,
    getLinks,
    getOrganizations,
    getOrganizationsSelect,
    managers,
    getManagers,
    organizationsSelected,
    organizations,
    createLink,
    updateLink,
    deleteLink,
    createQuestion,
    deleteQuestion,
    error,
    getVerticals,
    verticals,
    getQuestions,
    setError,
  } = useLink();

  const [createManagers, setCreateManagers] = useState<IManager[] | undefined>(
    [],
  );
  const [isMultiplyChecked] = useState<boolean>(false);

  const [filters, setFilters] = useState<IOrganizationFilters>({});

  useEffect(() => {
    const fetchData = async () => {
      await getLinks();
      await getOrganizations();
      await getVerticals();
      await getManagers();
      await getQuestions();
      await getOrganizationsSelect();
    };
    if (me) {
      fetchData();
    }
  }, [me]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getOrganizations(filters);
    };

    if (me) {
      fetchData();
    }
  }, [filters]);

  const handleDropFilters = () => {
    setFilters({});
  };
  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Опросы</h1>
      <div className={styles.buttonContainer}>
        <PrimaryButton onClick={handleOpenModal} text={'Cоздать опрос'} />
        <div className={styles.filterContainer}>
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Организация'}
            value={filters.organizationId}
            style={{ width: '150px' }}
            onChange={(v) => {
              v
                ? setFilters({ ...filters, organizationId: v })
                : setFilters((prevState) => ({
                    ...(prevState.managerId && {
                      managerId: prevState.managerId,
                    }),
                  }));
            }}
            options={organizationsSelected?.map((organization) => {
              return {
                value: organization.id,
                label: organization.title,
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
          <Select
            showSearch
            allowClear={true}
            className={'select'}
            placeholder={'Менеджер'}
            value={filters.managerId}
            style={{ width: '150px' }}
            onChange={(v) => {
              v
                ? setFilters({ ...filters, managerId: v })
                : setFilters((prevState) => ({
                    ...(prevState.organizationId && {
                      organizationId: prevState.organizationId,
                    }),
                  }));
            }}
            options={managers?.map((manager) => {
              return {
                value: manager.id,
                label: `${manager.surname} ${manager.name}`,
              };
            })}
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
            }
          />
        </div>
        <PrimaryButton
          onClick={handleDropFilters}
          text={'Сбросить фильтры'}
          isPlusVisible={false}
        />
      </div>
      <LinksTable
        links={
          links?.map((link) => {
            return {
              url: `${window.location.origin}/${link.id}`,
              key: link.id,
              ...link,
            };
          }) as Item[]
        }
        verticals={verticals}
        organizations={organizations}
        deleteLink={deleteLink}
        updateLink={updateLink}
        createQuestion={createQuestion}
        deleteQuestion={deleteQuestion}
        error={error}
        isEditable={true}
        setError={setError}
      />
      <Modal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        className={styles.modal}
      >
        <p
          style={{
            fontSize: '28px',
            color: EColors.GRANITE_GRAY,
            textWrap: 'wrap',
            fontWeight: 700,
            lineHeight: '36px',
            fontFamily: 'Manrope',
          }}
        >
          Создание ссылки на опрос
        </p>
        <Form
          name="link"
          form={form}
          onFinish={async (values) => {
            await createLink({
              forOrganizationId: values.forOrganizationId.toString(),
              isMultiplyResponse: isMultiplyChecked,
              managerId: values.managerId,
              verticalId: values.verticalId,
            });
            handleCloseModal();
          }}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '10px',
            justifyContent: 'start',
          }}
        >
          <Form.Item<FieldType>
            name="verticalId"
            rules={[{ required: true, message: 'Поле необходимо заполнить!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Вертикаль'}
              style={{ width: '283px' }}
              options={verticals?.map((vertical) => {
                return {
                  value: vertical.id,
                  label: vertical.title,
                };
              })}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase(),
                )
              }
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="forOrganizationId"
            rules={[{ required: true, message: 'Поле необходимо заполнить!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Организация'}
              style={{ width: '283px' }}
              onChange={(value, option) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                setCreateManagers(option.managers);
              }}
              options={organizationsSelected
                ?.filter((organization) => organization.managers.length > 0)
                ?.map((organization) => {
                  return {
                    value: organization.id,
                    label: organization.title,
                    managers: organization.managers,
                  };
                })}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase(),
                )
              }
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="managerId"
            rules={[{ required: true, message: 'Поле необходимо заполнить!' }]}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Менеджер'}
              disabled={createManagers && createManagers?.length === 0}
              style={{ width: '283px' }}
              options={createManagers?.map((manager) => {
                return {
                  value: manager.id,
                  label: `${manager.surname} ${manager.name}`,
                }; // Assuming manager has a 'name' property
              })}
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(
                  input.toLowerCase(),
                )
              }
            />
          </Form.Item>
          {/*<Form.Item<FieldType>*/}
          {/*  label="Множественный опрос"*/}
          {/*  name="isMultiplyResponse"*/}
          {/*  labelCol={{ span: 24 }}*/}
          {/*  wrapperCol={{ span: 24 }}*/}
          {/*>*/}
          {/*  <Checkbox*/}
          {/*    style={{}}*/}
          {/*    checked={isMultiplyChecked}*/}
          {/*    onClick={() => setIsMultiplyChecked((prev) => !prev)}*/}
          {/*  />*/}
          {/*</Form.Item>*/}
          <Form.Item style={{ width: '100%' }}>
            <Button
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'black',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                fontFamily: 'Manrope',
              }}
              className={styles.button}
              type="primary"
              htmlType="submit"
            >
              Ок
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
