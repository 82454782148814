import { Button, Form, FormProps, Input } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate, fetchMe } from '../../../features/user/userSlice';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';

type TLoginType = {
  publicKey?: string;
  privateKey?: string;
};

export const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const me = useSelector((state: RootState) => state.me.data);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      dispatch(fetchMe(jwt));
      if (me) {
        navigate('/admin');
      }
    }
  }, [me]);

  const onFinish: FormProps<TLoginType>['onFinish'] = async (values) => {
    if (!values.publicKey || !values.privateKey) {
      return;
    }
    const response = await dispatch(
      authenticate({
        privateKey: values.privateKey,
        publicKey: values.publicKey,
      }),
    );

    if (authenticate.fulfilled.match(response)) {
      navigate('/admin');
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Form
        name="login"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{
          maxWidth: 1000,
          flexDirection: 'column',
          gap: '10px',
          color: 'white',
          overflow: 'hidden',
        }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<TLoginType>
          label={<label style={{ color: 'white' }}>Почта</label>}
          name="publicKey"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TLoginType>
          label={<label style={{ color: 'white' }}>Пароль</label>}
          name="privateKey"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="default" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
